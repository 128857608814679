<template>
    <!-- Invited User Register Component to Set His Password -->
    <div class="page-layout simple registerinviteblock right-sidebar inner-sidebar">
        <div class="account-type-block">
            <div id="mail" style="">
                <div class="content-section registerinviteblock-section" style="">
                    <div class="center-section register-left-section" style="background-color: #0d84f2;overflow-y: scroll;margin-bottom: 20px;">
                        <div class="registeraccounttypecard registeraccounttype-over-card mt-4 personal">
                            <div style="font-size: 18px;color: #fff;font-weight: 600;padding: 25px 10px;margin: 0 auto;text-align: center;">
                                DigiCRM
                            </div>
                            <div class="registeraccounttypecard-body mb-10" style="padding: 55px 85px 0px;text-align: center;">
                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/invite.svg" onmousedown="return false;" ondragstart="return false;" class="img-fluid" style="max-width: 330px;"/>
                            </div>
                        </div>
                    </div>
                    <div class="fuse-sidebar registeraccounttype-sidebar sidebar left-positioned open locked-open" style="border-radius: 15px;overflow-y: scroll;">
                        <div class="preview-elements details-block mt-4">
                            <div style="font-size: 18px;color: #0d84f2;font-weight: 600;padding: 25px 10px 10px;margin: 0 auto;text-align: center;">
                                Register to DigiCRM
                            </div>
                            <div class="px-4 py-4">
                              <form autocomplete="off">
                                <input autocomplete="off" name="hidden" type="text" style="display:none;">
                                <div class="row">
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group fieldGroup">
                                            <input class="inputField" id="first_name" data-vv-scope="userData" name="first_name" :maxlength="maxlengthaccountfirstname" v-model.trim="basic_information.first_name" data-vv-as="First Name" type="text" v-validate="'required'" placeholder="Enter First Name">
                                            <span class="highlightInput"></span>
                                            <span class="inputBorder"></span>
                                            <label class="inputLabel fs-14 fw-600">First Name<sup class="fs-14 ml-02 mandatory">*</sup></label>
                                            <span class="character-count text-grey">{{maxlengthaccountfirstname - basic_information.first_name.length}}/20</span>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.first_name')">{{errors.first('userData.first_name')}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group fieldGroup">
                                            <input class="inputField" id="last_name" type="text" v-model.trim="basic_information.last_name" :maxlength="maxlengthaccountlastname" data-vv-as="Last Name" data-vv-scope="userData" name="last_name" v-validate="'required'" placeholder="Enter Last Name">
                                            <span class="highlightInput"></span>
                                            <span class="inputBorder"></span>
                                            <label class="inputLabel fs-14 fw-600">Last Name<sup class="fs-14 ml-02 mandatory">*</sup></label>
                                            <span class="character-count text-grey">{{maxlengthaccountlastname - basic_information.last_name.length}}/20</span>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.last_name')">{{errors.first('userData.last_name')}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group fieldGroup">
                                            <input class="inputField" id="primary_email" :disabled="true"  v-model.trim="basic_information.primary_email"
                                            data-vv-scope="userData" data-vv-as="Personal Email" type="text" name="primary_email" placeholder="Enter Email">
                                            <span class="highlightInput"></span>
                                            <span class="inputBorder"></span>
                                            <label class="inputLabel fs-14 fw-600">Personal Email<sup class="fs-14 ml-02 mandatory">*</sup></label>
                                            <!-- <span class="character-count text-grey">{{maxlengthaccountprimaryemail - basic_information.primary_email.length}}/230</span> -->
                                            <!-- <span class="invalid-feedback-form text-danger" v-if="primaryEmailExists">Personal Email Already Exists!!</span> -->
                                            <!-- <span class="invalid-feedback-form text-danger" v-if="!primaryEmailValid">Please Enter Valid Email</span>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.primary_email')">{{errors.first('userData.primary_email')}}</span> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group">
                                            <input class="inputField" oncopy="return false" style="padding-right: 40px !important;" onpaste="return false" oncut="return false" id="password" name="password" data-vv-scope="userData" :type="passwordTypeRegister" minlength="8" maxlength="20" ref="password" v-model="basic_information.password" v-validate="'required'" placeholder="Enter Password">
                                            <!-- v-validate="'required|min:8|verify_password'" -->
                                            <span class="inputBorder"></span>
                                            <label class="inputLabel fs-14 fw-600">Password<sup class="fs-14 ml-02 mandatory">*</sup></label>
                                            <!-- <span class="character-count text-grey">{{maxlengthaccountpassword - basic_information.password.length}}/20</span> -->
                                            <span class="input-group-addon" type="password" style="cursor: pointer;position: absolute;top: 28px;text-align: right;right: 0px;margin-right:2px;border-radius: 0px !important;box-shadow: none !important;padding: 0px 0px !important;height: 28px;">
                                                <i :class="[passwordIconRegister]" @click="hidePasswordRegister = !hidePasswordRegister" style="color: #0d84f2;font-size: 16px;width: 18px;height: 18px;line-height: 18px;"></i>
                                            </span>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;padding-right: 40px;" v-show="errors.has('userData.password') && savedClick">{{errors.first("userData.password")}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-group">
                                            <input class="inputField" style="padding-right: 40px !important;" oncopy="return false" onpaste="return false" oncut="return false" id="confirm_password" data-vv-scope="userData" name="confirm_password" :type="passwordConfirmTypeRegister" minlength="8" maxlength="20"
                                            data-vv-as="Confirm Password" v-validate="'required|confirmed:password'"  placeholder="Enter Confirm Password">
                                            <span class="inputBorder"></span>
                                            <label class="inputLabel fs-14 fw-600">Confirm Password<sup class="fs-14 ml-02 mandatory">*</sup></label>
                                            <span class="input-group-addon" type="password" style="cursor: pointer;position: absolute;top: 28px;text-align: right;right: 0px;margin-right:2px;border-radius: 0px !important;box-shadow: none !important;padding: 0px 0px !important;height: 28px;">
                                                <i :class="[passwordConfirmIconRegister]" @click="hideConfirmPasswordRegister = !hideConfirmPasswordRegister" style="color: #0d84f2;font-size: 16px;width: 18px;height: 18px;line-height: 18px;"></i>
                                            </span>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.confirm_password')">{{errors.first('userData.confirm_password')}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <span class="text-secondary whiteSpace fs-13 fw-600">Phone Number<sup class="fs-14 ml-1 mandatory">*</sup></span>
                                        <vue-tel-input class="teleContact mt-2" id="primary_phone_number" name="phone_number" v-model="basic_information.primary_phone.phone_number" ref="telInput" placeholder="Enter Phone Number"   v-on:country-changed="countryChanged($event, 'employee_phone', 0)"
                                        data-vv-scope="userData" data-vv-as="Phone Number" v-validate="{required:true,min:15}" autocomplete="off" :disabledFormatting="false" :maxLen="maxPhoneLength" :valid-characters-only="true"></vue-tel-input>

                                        <!-- <VuePhoneNumberInput class="digiphone" id="registerphonenumber" @update="phonenumber_valid($event,'primary',null)"
                                        v-model="basic_information.primary_phone.phone_number" name="phone_number" placeholder="Please Enter your Phone Number" data-vv-scope="userData"  v-validate="'required'"
                                        :clearable= "false" :color="color" :valid-color="validColor" data-vv-as="Phone Number" :required= "true"/> -->
                                        <span class="invalid-feedback-form text-danger" style="text-transform: none !important;padding-right: 40px;" v-show="errors.has('userData.phone_number')">{{errors.first("userData.phone_number")}}</span>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="form-check form-check-inline">
                                            <label class="form-label-radio text-secondary fw-600">
                                                Gender<sup class="fs-14 ml-02 mandatory">*</sup>
                                            </label>
                                        </div>
                                        <div class="preview-elements previewElementsContacts radiooptions mt-3">
                                            <div class="form-check form-check-inline gender-radio">
                                                <label class="form-check-label check-label-dc">
                                                    <input class="form-check-input" id="gender_male" data-vv-scope="userData" data-vv-as="Gender" type="radio" name="gender" v-model="basic_information.gender" v-validate="'required'" selected value="Male">
                                                    <span class="radio-icon"></span>
                                                    <span class="form-check-description fw-600">Male</span>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline gender-radio">
                                                <label class="form-check-label check-label-dc">
                                                    <input class="form-check-input" id="gender_female" data-vv-scope="userData" data-vv-as="Gender" type="radio" name="gender" v-model="basic_information.gender" v-validate="'required'" value="Female">
                                                    <span class="radio-icon"></span>
                                                    <span class="form-check-description fw-600">Female</span>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline gender-radio">
                                                <label class="form-check-label check-label-dc">
                                                    <input class="form-check-input" id="gender_custom" data-vv-scope="userData" data-vv-as="Gender" type="radio" name="gender" v-model="basic_information.gender" v-validate="'required'" value="Others">
                                                    <span class="radio-icon"></span>
                                                    <span class="form-check-description fw-600">Custom</span>
                                                </label>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.gender')">{{errors.first('userData.gender')}}</span>
                                        </div>
                                    </div>
                                    <div class="w-100 mt-4" v-if="basic_information.gender=='Others'">
                                        <div class="col-lg-12">
                                            <span class="text-secondary whiteSpace vselectLabel fw-600">Please Refer to me as<sup class="fs-14 ml-1 mandatory">*</sup></span>
                                            <v-select class="vselect-custom" id="gender_custom_gender" data-vv-scope="userData" v-model="basic_information.custom_gender.preposition" v-validate="'required'" required name="preposition" placeholder="Select Preposition" :options="['Mr.', 'Mrs.', 'Miss', 'Sir', 'Madam']" style="width: 100%;"></v-select>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.preposition')">{{errors.first('userData.preposition')}}</span>
                                        </div>
                                        <div class="col-lg-12 mt-4">
                                            <div class="form-group">
                                                <input class="inputField" id="gender_gender_optional" type="text" data-vv-scope="userData" name="custom_gender" data-vv-as="Gender" v-model="basic_information.custom_gender.gender" placeholder="Enter Gender">
                                                <span class="inputBorder"></span>
                                                <label class="inputLabel fs-14 fw-600">Gender(Optional)</label>
                                                <!-- <span class="invalid-feedback-form text-danger" v-show="errors.has('userData.gender')">This Field Is Required</span> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="">
                                            <span class="text-secondary whiteSpace vselectLabel fw-600">Country<sup class="fs-14 ml-02 mandatory">*</sup></span>
                                            <v-select class="vselect-custom" id="country" placeholder="Select Country" :clearable="false" :filterable="true" v-model="basic_information.address[0].country" :options="countries" data-vv-as="Country" style="width: 100%;margin-top: 7px;" data-vv-scope="userData" name="country" v-validate="'required'"></v-select>
                                            <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userData.country')">{{errors.first('userData.country')}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-7 mb-5 text-center">
                                        <button type="button" class="btn btn-secondary btn-smm" id="registerbtn" v-on:click="updateUser()">
                                            Save
                                        </button>
                                    </div>
                                </div>
                              </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "./eventBus/nav-bar-event.js";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import countries from './json/countries.json'
import { User } from "./mixins/user";
import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import axios from "axios";

export default {
    data() {
        return {
            maxlengthaccountfirstname: 20,
            maxlengthaccountlastname: 20,
            maxlengthaccountpassword: 20,
            maxlengthaccountprimaryemail: 230,
            EmailExists: false,
            hidePasswordRegister: true,
            hideConfirmPasswordRegister: true,
            countries: countries,
            //maxlengthaccountpassword: 20,
            maxPhoneLength: 15,
            basic_information: {
                first_name: "",
                last_name: "",
                middle_name: "",
                cover_pic:
                  "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg",
                profile_pic: "/static/images/company-logo.png",
                password: "",
                blood_group: "",
                primary_email: "",
                primary_phone: {
                  phone_type: "",
                  phone_number: "",
                  country_code: "",
                  dial_code: ""
                  //isvalid: "",
                },
                gender: "",
                custom_gender: {
                  gender: "",
                  preposition: "",
                },
                emergency: {
                  emergency_contact_name: "",
                  emergency_notes: "",
                  emergency_contact_relationship: "",
                  emergency_contact_number: {
                    phone_type: "",
                    phone_number: "",
                    country_code: "",
                    dial_code: ""
                    //isvalid: "",
                  },
                },
                email: [],
                phone_details: [],
                address: [
                  {
                    street: "",
                    city: "",
                    township: "",
                    state: "",
                    country: "",
                    zip_code: "",
                  },
                ],
                about: "",
                preferences: {
                  food_preferences: {
                    favourite_food: "",
                    favourite_drink: "",
                    food_allergy: "",
                  },
                  room_preferences: {
                    room_type: "",
                    bed_type: "",
                    pillow_type: "",
                    bed_sheet_type: "",
                  },
                },
              },
              savedClick: false,
              country: null
        };
    },
		mixins: [User],
    components: { VueTelInput },
    methods: {

        // Sending Dial Code and Country Code
        countryChanged(country, variable, index) {
          switch(variable) {
            case 'employee_phone':
              this.basic_information.primary_phone.dial_code = country.dialCode
              this.basic_information.primary_phone.country_code = country.iso2
              break
          }
        },

        // Getting Data For Invited User API
        GetUserData() {
            this.$http
              .post("/users/get_data_for_registration", { user_id: this.$route.params.id })
              .then(response => {
                if (response.data.status_id == 1) {
                  this.basic_information = response.data.response;
                }
              })
              .catch(error => {
                console.log(error);
              });
        },

        // Update the Data and Getting Registered the Ueer
        updateUser(){
          this.savedClick = true
          this.$validator.validateAll("userData").then(result => {
            if (result) {
                this.$http
                  .post("/users/register_invited_user", { data: this.basic_information })
                  .then(response => {
                    if (response.data.status_id == 1) {
                      // this.basic_information = response.data.response;
                      // this.$cookie.set("Token", res.data.token, {expires: 7, domain:"digicollect.com"});
                      // this.$store.commit("setToken", res.data.token);
                      this.$cookie.set("Token", response.data.token);
                      axios.defaults.headers.common.Authorization =
                        response.data.token;
                      this.savedClick = false
                      window.location.reload();
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  });
            }
          });
        }
    },
    created() {
        this.GetUserData();
        const dict = {
            custom: {
                first_name: {
                    required: () => 'First Name is Required'
                },
                last_name: {
                    required: () => 'Last Name is Required'
                },
                primary_email: {
                    required: () => 'Personal Email is Required',
                    regex: () => 'Please Enter Valid Email'
                },
                password: {
                    required: () => 'Password is Required',
                    //min_value: () => 'Password should be of Minimum 8 Characters'
                },
                confirm_password: {
                    required: () => 'Confirm Password is Required',
                    confirmed: () => 'Confirm Password does not Match',
                    //min_value: () => 'Confirm Password should be of Minimum 8 Characters'
                },
                phone_number: {
                    required: () => "Phone Number is Required",
                    min: () => "Phone Number must Contain more than 9 Numbers",
                    regex: () => "Phone Number must Contain Valid Format"
                },
                gender: {
                    required: () => "Gender is Required",
                },
                preposition: {
                    required: () => "Preposition is Required",
                },
                country: {
                    required: () => "Country is Required",
                }
            }
          }
        this.$validator.localize('en', dict)
    },
    computed: {
        passwordTypeRegister() {
            return this.hidePasswordRegister ? 'password' : 'text'
        },
        passwordIconRegister() {
            return this.hidePasswordRegister ? 'icon-eye' : 'icon-eye-off'
        },
        passwordConfirmTypeRegister() {
            return this.hideConfirmPasswordRegister ? 'password' : 'text'
        },
        passwordConfirmIconRegister() {
            return this.hideConfirmPasswordRegister ? 'icon-eye' : 'icon-eye-off'
        },
        loggedInUser() {
          return this.$store.getters.loggedInUser;
        },
    },
};
</script>
<style>
.page-layout.simple.registerinviteblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerinviteblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.registerinviteblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  background-color: #0d84f2;
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.registeraccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  z-index: 1000;
}
.fuse-sidebar.registeraccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerinviteblock.right-sidebar.inner-sidebar
  .content-section.registerinviteblock-section
  .sidebar.locked-open {
  background: #d6e2ef !important;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #0d84f2;
}
.page-layout.simple.registerinviteblock.right-sidebar.inner-sidebar
  .content-section.registerinviteblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.registeraccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerinviteblock.right-sidebar.inner-sidebar
  .content-section.registerinviteblock-section
  .center-section {
  order: 1;
  padding: 10px 20px;
  background-color: #0d84f2;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerinviteblock.left-sidebar.inner-sidebar
  .content-section.registerinviteblock-section
  .center-section,
.page-layout.simple.registerinviteblock.right-sidebar.inner-sidebar
  .content-section.registerinviteblock-section
  .center-section {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.registeraccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.registeraccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.registeraccounttype-list-item .registeraccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.registeraccounttype-list-item .registeraccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.registeraccounttype-list-item img.registeraccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.registeraccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #0d84f2;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
</style>