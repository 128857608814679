export const User = {
    data() {
        return {
            registeredClick: false,
            userDietaryPreference: [],
            basic_information: {
                first_name: "",
                last_name: "",
                middle_name: "",
                cover_pic: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg",
                profile_pic: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png",
                password: "",
                blood_group: "",
                primary_email: "",
                primary_phone: {
                    phone_type: "",
                    phone_number: "",
                    country_code: "",
                    dial_code: ""
                    //isvalid: "",
                },
                gender: "",
                custom_gender: {
                    gender: "",
                    preposition: "",
                },
                social_media: [{
                    account_type: "Facebook",
                    account_url: "",
                }, ],
                emergency: {
                    emergency_contact_name: "",
                    emergency_notes: "",
                    emergency_contact_relationship: "",
                    emergency_contact_number: {
                        phone_type: "",
                        phone_number: "",
                        country_code: "",
                        dial_code: ""
                        //isvalid: "",
                    },
                },
                email: [],
                phone_details: [],
                address: [{
                    street: "",
                    city: "",
                    township: "",
                    state: "",
                    country: "",
                    zip_code: "",
                    address_type: ""
                }, ],
                about: "",
                preferences: {
                    food_preferences: {
                        favourite_food: "",
                        favourite_drink: "",
                        food_allergy: "",
                    },
                    room_preferences: {
                        room_type: "",
                        bed_type: "",
                        pillow_type: "",
                        bed_sheet_type: "",
                    },
                    dietary_preferences: []
                },
            },
            check: true,
            addressData: {
                street: "",
                city: "",
                township: "",
                state: "",
                country: "",
                zip_code: "",
                address_type: ""
            },
            customEmailType: "",
            customUserEmailType: '',
            customPhoneType: "",
            customUserPhoneType: "",
            primaryEmailExists: false,
            primaryEmailValid: true,
            profile_data: {
                first_name: "",
                full_name: "",
                middle_name: "",
                last_name: "",
                blood_group: "",
                date_of_birth: "",
                primary_email: "",
                cover_pic: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg",
                profile_pic: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png",
                primary_phone: {
                    phone_type: "",
                    phone_number: "",
                    country_code: "",
                    dial_code: ""
                    //isvalid: "",
                },
                gender: "",
                custom_gender: {
                    gender: "",
                    preposition: "",
                },
                social_media: [{
                    account_type: "Facebook",
                    account_url: "",
                }, ],
                emergency: {
                    emergency_contact_name: "",
                    emergency_notes: "",
                    emergency_contact_relationship: "",
                    emergency_contact_number: {
                        phone_type: "",
                        phone_number: "",
                        country_code: "",
                        dial_code: ""
                        //isvalid: "",
                    },
                },
                email: [],
                phone_details: [],
                address: [],
                about: "",
                preferences: {
                    food_preferences: {
                        favourite_food: "",
                        favourite_drink: "",
                        food_allergy: "",
                    },
                    room_preferences: {
                        room_type: "",
                        bed_type: "",
                        pillow_type: "",
                        bed_sheet_type: "",
                    },
                    dietary_preferences: []
                },
            },
        };
    },
    computed: {
        // checkBranchExists(){
        // }
        // checkExists() {
        //   // if i add this.duplicate.length not working
        //   if (this.primaryEmailExists) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    methods: {
        // Checking Personal(Primary) Email Exists and Validating(Public Domains)
        checkPrimaryEmail() {
            this.$http
                .post("/check_email_validity", {
                    email_id: this.basic_information.primary_email,
                    email_type: "personal"
                })
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.primaryEmailExists = response.data.exists;
                        this.primaryEmailValid = response.data.valid;
                    } else
                        this.primaryEmailValid = response.data.valid;
                })
                .catch(error => {
                });
        },
        // Save Edit User Function for all Tabs
        saveEditUser(saved_data) {
            this.isSubmitted = true;
            var moment = require('moment');
            let str = '';
            let date = '';
            if (saved_data.date_of_birth && saved_data.date_of_birth.value) {
                str = saved_data.date_of_birth.value
                date = moment(str)
                saved_data.date_of_birth.value = date.format()
            } else if (saved_data.date_of_birth && !saved_data.date_of_birth.value) {
                delete saved_data["date_of_birth"];
            }
            if (saved_data.primary_phone) {
                saved_data.primary_phone.value.phone_number = saved_data.primary_phone.value.phone_number.replace(/\s+/g, '');
                if (saved_data.primary_phone.country_code) {
                    delete saved_data.primary_phone["country_code"];
                }
                if (saved_data.primary_phone.dial_code) {
                    delete saved_data.primary_phone["dial_code"];
                }
            }
            if (saved_data.phone_details) {
                saved_data.phone_details.forEach(x => {
                    x.value.phone_number = x.value.phone_number.replace(/\s+/g, '');
                    if (x.country_code) {
                        delete x["country_code"];
                    }
                    if (x.dial_code) {
                        delete x["dial_code"];
                    }
                });
            }
            this.$validator.validateAll("profile_data").then(result => {
                if (result == true) {
                    this.editUser(saved_data)
                } else {}
            });
        },
        hideEdit() {
            this.$emit('deactivateEditMode')
        },
        // Edit User API Call
        editUser(editedData) {
            this.requestResponse = true;
            this.$http
                .post("/users/edit_user", {
                    edit_data: editedData
                })
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.requestResponse = false
                        this.$modal.show("save_profile_data");
                    }
                })
                .catch(error => {
                });
        },

        // Upload and Edit Image Function(Converts to base64 and API Call (In future save image to CDN))
        saveEditImage(pictype, editpic) {
            if (pictype == 'cover_pic') {
                this.$http
                    .post("/users/edit_profile_cover_pic", {
                        'cover_pic': editpic
                    })
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.profile_data.cover_pic = editpic
                            this.$awn.success("Uploaded Successfully")
                        }
                    })
                    .catch(error => {
                    });
            } else {
                this.$http
                    .post("/users/edit_profile_cover_pic", {
                        'profile_pic': editpic
                    })
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.profile_data.profile_pic = editpic
                            this.$awn.success("Uploaded Successfully")
                        }
                    })
                    .catch(error => {
                    });
            }
        },

        // Validation 
        validateInput(scope) {
            if (scope == "profile_data") {
                var moment = require("moment");
                let str = this.basic_information.date_of_birth;
                let date = moment(str);
                this.basic_information.date_of_birth = date.format();
                // if (!this.basic_information.primary_phone.isvalid) {
                //   this.basic_information.primary_phone.phone_number = "";
                // }
                // if (
                //   !this.basic_information.emergency.emergency_contact_number.isvalid
                // ) {
                //   this.basic_information.emergency.emergency_contact_number.phone_number =
                //     "";
                // }
                // for (var i = 0; i < this.basic_information.phone_details.length; i++) {
                //   if (!this.basic_information.phone_details[i].isvalid) {
                //     return this.$validator.validate(
                //       "phone_details",
                //       (this.basic_information.phone_details[i].phone_number = "")
                //     );
                //   }
                // }
                return !this.primaryEmailExists && this.$validator.validateAll(scope);
            }
            return this.$validator.validateAll(scope);
            // if (scope == "preferenceData") {
            //   // submit data here
            // }
        },

        // Register User Component and Set token and adding Address Type
        createUser() {
            this.registeredClick = true
            this.$validator.validateAll("profile_data").then(result => {
                this.basic_information.address[0].address_type = "Current Address (Shipping Address)"
                if (result) {
                    this.$http
                        .post("/users/register_user", this.basic_information)
                        .then(res => {
                            this.registeredClick = false
                            if (res.data.status_id == 1) {
                                this.$cookie.set("Token", res.data.token, {
                                    expires: 7,
                                    domain: "digicollect.com"
                                });
                                this.$store.commit("setToken", res.data.token);
                                window.location.reload();
                            } else {
                                this.$swal({
                                    title: "Error!",
                                    html: 'Not Able to Register. Please Contact ' +
                                        '<a href="mailto:helpdesk@digicollect.com" style="color: #0d84f2">DigiCollect Helpdesk</a>',
                                    type: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: "#0475f3",
                                });
                            }
                        });
                }
            });
        },
        switchToProfile(id) {
            this.$router.push("/PersonalInfoView/" + id);
        },
        // Get User data API in profile view
        GetUserData(id) {
            this.ajax_call_in_progress = true;
            this.$http
                .post("/users/get_user_data", {
                    user_id: id
                })
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.profile_data = response.data.response;
                        if (!("primary_email" in this.profile_data)) {
                            Object.assign(this.profile_data, {
                                primary_email: {
                                    value: "",
                                    privacy: "contacts",
                                },
                            });
                        };
                        if (!("primary_phone" in this.profile_data)) {
                            Object.assign(this.profile_data, {
                                primary_phone: {
                                    value: {
                                        country_code: "",
                                        dial_code: "",
                                        phone_number: "",
                                        phone_type: "",
                                    },
                                    privacy: "contacts",
                                    verified: false,
                                }
                            });
                        };
                        if ("primary_phone" in this.profile_data && !("value" in this.profile_data.primary_phone)) {
                            Object.assign(this.profile_data.primary_phone, {
                                value: {
                                    country_code: "",
                                    dial_code: "",
                                    phone_number: "",
                                    phone_type: "",
                                },
                            });
                        };
                        if (!("address" in this.profile_data)) {
                            Object.assign(this.profile_data, {
                                address: [{
                                    value: {
                                        street: "",
                                        city: "",
                                        township: "",
                                        state: "",
                                        country: "",
                                        zip_code: "",
                                    },
                                    privacy: "contacts",
                                }],
                            });
                        } else if (!(this.profile_data.address.length)) {
                            this.profile_data.address.push({
                                value: {
                                    street: "",
                                    city: "",
                                    township: "",
                                    state: "",
                                    country: "",
                                    zip_code: "",
                                },
                                privacy: "contacts",
                            });
                        }
                        if (!("preferences" in this.profile_data)) {
                            Object.assign(this.profile_data, {
                                preferences: {
                                    value: {
                                        food_preferences: {
                                            favourite_food: "",
                                            favourite_drink: "",
                                            food_allergy: "",
                                        },
                                        room_preferences: {
                                            room_type: "",
                                            bed_type: "",
                                            pillow_type: "",
                                            bed_sheet_type: "",
                                        },
                                        dietary_preferences: [],
                                    },
                                    privacy: "contacts",
                                },
                            });
                        }
                        if (!("health" in this.profile_data)) {
                            Object.assign(this.profile_data, {
                                health: {
                                    value: {
                                        blood_group: "",
                                        pre_existing_conditions: [],
                                        allergies: [],
                                        medications: [],
                                        notes: "",
                                    },
                                    privacy: "contacts",
                                },
                            });
                        }
                        this.ajax_call_in_progress = false;
                    }
                })
                .catch(error => {
                });
        },
        // Dietary Preference API
        GetDietaryPreference() {
            this.$http
                .get("/users/get_preferences")
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.userDietaryPreference = response.data.response.value;
                    }
                })
                .catch(error => {
                });
        },
        // Mutliple Email Adding Function
        emailAdding(value) {
            this.basic_information.email.push(
                JSON.parse(JSON.stringify({
                    email_type: value,
                    email_id: ""
                }))
            );
        },
        // Mutliple Email Adding Function
        addEmails() {
            this.editCommunicationData.email.push(
                JSON.parse(JSON.stringify({
                    privacy: "contacts",
                    value: "",
                    value_type: "personal",
                }))
            );
        },
        // Mutliple Email Adding Function
        customEmail() {
            this.basic_information.email.push(
                JSON.parse(
                    JSON.stringify({
                        email_type: this.customEmailType,
                        email_id: ""
                    })
                )
            );
            this.customEmailType = "";
        },
        // Mutliple Email Adding Function
        customUserEmail() {
            this.basic_information.email.push(
                JSON.parse(
                    JSON.stringify({
                        email_type: this.customUserEmailType,
                        email_id: ""
                    })
                )
            );
            this.customUserEmailType = "";
        },
        // Mutliple Phone Number Adding Function
        phoneAdding(value) {
            this.basic_information.phone_details.push(
                JSON.parse(
                    JSON.stringify({
                        phone_type: value,
                        phone_number: "",
                        //country_code: "",
                        //isvalid: "",
                    })
                )
            );
        },
        // Mutliple Phone Number Adding Function
        addPhones() {
            this.editCommunicationData.phone_details.push(
                JSON.parse(
                    JSON.stringify({
                        value: {
                            phone_type: "",
                            phone_number: "",
                            country_code: "",
                            dial_code: "",
                        },
                        privacy: "contacts",
                        verified: false,
                    })
                )
            );
        },
        // Mutliple Phone Number Adding Function
        customPhone() {
            this.basic_information.phone_details.push(
                JSON.parse(
                    JSON.stringify({
                        phone_type: this.customPhoneType,
                        phone_number: "",
                        //country_code: "",
                        //isvalid: "",
                    })
                )
            );
            this.customPhoneType = "";
        },
        // Mutliple Phone Number Adding Function
        customUserPhone() {
            this.basic_information.phone_details.push(
                JSON.parse(
                    JSON.stringify({
                        phone_type: this.customUserPhoneType,
                        phone_number: "",
                        //country_code: "",
                        //isvalid: "",
                    })
                )
            );
            this.customUserPhoneType = "";
        },
        // Connection Status API
        getConnectionStatus(params) {
            if (this.loggedInUser._id != params.user_id) {
                this.$http
                    .post("/connections/get_connection_status", params)
                    .then(res => {
                        if (res.data.status_id == 1) {
                            if (res.data.response) this.connectionStatus = res.data.response;
                            else this.connectionStatus = {
                                connection_status: null
                            };
                        }
                    });
            }
        },
        // Change Connection Status and Get Connection Count 
        changeConnectionStatus(status, id, user_id = null) {
            this.requestResponse = true
            this.$http
                .post("/connections/change_connection_status", {
                    connection_status: status,
                    _id: id,
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.requestResponse = false
                        if (user_id) {
                            this.getConnectionStatus({
                                user_id: user_id,
                                request_type: this.requestType
                            });
                            this.getConnectionsCount();
                        } else {
                            this.getConnectionStatus({
                                user_id: this.$route.params.id,
                                request_type: this.requestType
                            });
                            this.getConnectionsCount();
                        }
                        if (status == "disconnected") {
                            this.$awn.success("Request Disconnected Successfully")
                        }
                        if (status == "delete") {
                            this.$awn.success("Request Deleted Successfully")
                        }
                        if (status == "connected") {
                            this.$awn.success("Request Accepted Successfully")
                        }
                    }
                });
        },
        // Request Connection API
        addConnection(company_id = null) {
            this.requestResponse = true
            var sender_company = null;
            var request_type = "personal";
            if (company_id) {
                request_type = "business";
                sender_company = company_id;
            }
            this.$http
                .post("/connections/add_connection", {
                    sent_to: this.$route.params.id,
                    request_type: request_type,
                    sender_company: sender_company
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.requestResponse = false;
                        this.getConnectionStatus({
                            user_id: this.$route.params.id,
                            request_type: this.requestType
                        });
                        this.$awn.success("Request Sent Successfully");
                    }
                });
        },
    },
};